<template>
  <el-dialog title="分配资金" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-button type="success" icon="el-icon-add" size="min" @click="addAllotButton()">新增分配</el-button>
    <el-button type="success" icon="el-icon-add" size="min" @click="saveAllotButton()">保存</el-button>&nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp;
    <span>可分配总金额{{ this.allotMonye }}</span>
    <el-table :data="allotFrom" border stripe style="width: 100%" show-summary>
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="分配金额" prop="allotMoney">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input-number class="edit-input" v-model="scope.row.allotMoney" placeholder="请输入内容"></el-input-number>
          </template>
          <span v-else>{{ scope.row.allotMoney }}</span>
        </template>
      </el-table-column>
      <el-table-column label="目标金额" prop="targetMoney">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input-number class="edit-input" v-model="scope.row.targetMoney" placeholder="请输入内容"></el-input-number>
          </template>
          <span v-else>{{ scope.row.targetMoney }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配置类别" prop="category">
        <template slot-scope="scope">
          <!--  -->
          <template v-if="scope.row.edit">
            <el-select v-model="scope.row.category" placeholder="请选择">
              <el-option v-for="item in attributionKeyList" :key="item.key" :label="item.label"
                :value="item.key"></el-option>
            </el-select>
            <!-- <el-input-number class="edit-input" v-model="scope.row.targetMoney" placeholder="请输入内容"></el-input-number> -->
          </template>
          <span v-else>{{ showLabelCat(scope.row.category) }}</span>
        </template>
      </el-table-column>


      <el-table-column label="处理原因" prop="disposeRemark">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input class="edit-input" v-model="scope.row.disposeRemark" placeholder="请输入内容"></el-input>
          </template>
          <span v-else>{{ scope.row.disposeRemark }}</span>
        </template>
      </el-table-column>

      <el-table-column label="开始时间" prop="beginData">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-date-picker v-model="scope.row.beginData" align="right" type="date" placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">{{ scope.row.beginData }}</el-date-picker>
          </template>
          <span v-else>{{ scope.row.beginData }}</span>
        </template>
      </el-table-column>

      <el-table-column label="结束时间" prop="endData">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-form :model="scope.row">
              <el-date-picker v-model="scope.row.endData" align="right" type="date" placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">{{ scope.row.endData }}</el-date-picker>
            </el-form>
          </template>
          <span v-else>{{ scope.row.endData }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="测试字段" prop="endData">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input class="edit-input" v-model="scope.row.lunchMoney" placeholder="请输入内容"></el-input>
          </template>
          <span v-else>{{scope.row.lunchMoney}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" prop="cancelMoney">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-edit" @click="editRowMember(scope.$index, allotFrom)"
            v-if="!scope.row.edit">编辑</el-button>

          <el-button @click.native.prevent="addtRow(scope.$index, scope.row)" type="text" size="small"
            v-if="scope.row.edit">保存</el-button>
          <el-button @click.native.prevent="deleteRow(scope.$index, allotFrom)" type="text" size="small">移除</el-button>
        </template>
        <!-- <template slot-scope="scope">
          <span style="color:#108ee9;cursor:pointer;" @click="details(scope.row.id)">删除</span>
        </template>-->
      </el-table-column>
    </el-table>


  </el-dialog>
</template>
<script>

import { list, addAllot, editAllot, addAll } from "@/api/capital/bank/bankAllot";
export default {
  name: "allotShow",
  data() {
    return {
      edit: false,
      dialogVisible: false,
      allotFrom: [],
      innerVisible: false,
      ids: null,
      title: null,
      allotAddFrom: {
        id: null,
        bankInfoId: null,
        allotMoney: null,
        disposeRemark: null,
        beginData: null,
        endData: null,
        show: false
      },
      radio: null,
      allotMonye: null,
      attributionKeyList: []
    };
  },
  methods: {


    //打开弹窗
    show(id, allotMonye) {
      console.log(allotMonye)
      this.dialogVisible = true;
      this.listButton(id);
      this.ids = id;
      this.allotMonye = allotMonye;
      this.attributionKeyList = this.$parent.attributionKeyList
    },
    listButton(id) {
      list({ id: id }).then(res => {
        this.allotFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
          this.$parent.getBankList()
        })
        .catch(_ => { });
    },
    addAllotButton() {
      var newAllotAddFrom = {
        edit: true
      };
      this.allotFrom.push(newAllotAddFrom);
      this.title = "新增分配";
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log(row);
      this.templateSelection = row;
    },
    deleteRow(index, rows) {
      console.log(rows);
      rows.splice(index, 1);
    },
    editRow(index, rows) {
      this.edit = !this.eidt;
    },
    editRowMember(index, row) {
      console.log(row);
      row.edit = Boolean(true);
      row[index].edit = Boolean(true);
    },
    addtRow(index, row) {
      row.edit = Boolean(false);
      row.bankInfoId = this.ids;
      console.log(row.id);
      if (row.id === undefined) {
        addAllot(row).then(res => { });
      } else {
        editAllot(row).then(res => { });
      }
      this.$parent.getBankList()
    },
    saveAllotButton() {
      addAll({ addXunBankAllotVOS: this.allotFrom }).then(res => {
        this.dialogVisible = false;
      })
    },
    showLabelCat(row) {
      var label='----';
      let foundItem = this.attributionKeyList.find(item => item.key === row);
      console.log(foundItem);
      if(foundItem!=undefined){
        label=foundItem.label
      }
      return label;
    }
  }
};
</script>