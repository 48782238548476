import { getAction,getByIdAction,postAction,putAction } from '@/api/common/request'
const baseURL = 'xun/work/XunBankAllot';
//调用 import { borrowList } from "@/api/capital/bank/bankAllot";

const list=(params)=>getAction(baseURL+"/list",params);

const addAllot=(params)=>postAction(baseURL+"/add",params);

const editAllot=(params)=>putAction(baseURL+"/edit",params);

const addAll=(params)=>postAction(baseURL+"/addAll",params);

export{
    list,
    addAllot,
    editAllot,
    addAll
}